import Vue from "vue";

const firebaseConfig = {
  apiKey: "AIzaSyCh2urlxAyba7B6pzicTvl13WKrH3bzZuE",
  authDomain: "pob-trading-company.firebaseapp.com",
  databaseURL: "https://pob-trading-company-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "pob-trading-company",
  storageBucket: "pob-trading-company.appspot.com",
  messagingSenderId: "161591851585",
  appId: "1:161591851585:web:531a699c24fffe989f24c0",
  measurementId: "G-PQXJ1Z0C3P"
};

Vue.mixin({
  data: function () {
    return {
      get companyName() {
        return "POB TRADING COMPANY"; //公司名稱
      },
      get companyDomain() {
        return "https://pob-trading.xyz/"; //公司HR系統網址
      },
    };
  },
});

//  溫馨提示：建議使用較淺的顏色
//  歡迎回來的背景顏色
const panelColor        = "#e8f2fa"

//  溫馨提示：建議使用較深的顏色
//  側邊欄的背景顏色
const sideBarColor      = "#000"

//        /||\
//       / || \
//      |------|
//      |  ||  |
//      |  ||  |
//      |  ||  |
//      |  ||  |
//       \ || /
//  不用理 \||/
//        /||\
//       / || \
export {
    firebaseConfig,
    panelColor,
    sideBarColor
}